import {Injectable, Optional} from '@angular/core';
import {AppEnvironment, CommonLibConfig} from '../models';

@Injectable({providedIn: 'root'})
export class ConfigService {
    /**
     * Base API URL.
     */
    public get apiUrl(): string {
        return this.config.apiUrl;
    }

    /**
     * Base static files API URL.
     */
    public get filesUrl(): string {
        return this.config.filesUrl;
    }

    /**
     * Current environment configuration.
     */
    public get environment(): AppEnvironment {
        return this.config.environment;
    }

    constructor(@Optional() private config: CommonLibConfig) {
    }
}
