import {Injectable, Renderer2} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FileModel, MediaMimeType} from '../../models';
import {ApiMediaImagesService} from '../../services';

@Injectable()
export class FileUploaderService {
    constructor(private renderer: Renderer2, private media: ApiMediaImagesService) {
    }

    public openFileSelectionWindow(
        host: HTMLElement,
        supportedFileTypes: MediaMimeType[] = [MediaMimeType.JPG, MediaMimeType.PNG]
    ): Observable<UploadInfo> {

        const multiple = false; // not supported
        const subject = new Subject<UploadInfo>();
        let input = this.renderer.createElement('input') as HTMLInputElement;
        this.renderer.appendChild(host, input);
        this.renderer.addClass(input, 'outside-of-screen');
        input.type = 'file';
        input.setAttribute('multiple', `${multiple}`);
        input.setAttribute('accept', supportedFileTypes.join(','));

        input.onchange = () => {
            const selectedFiles = input.files as FileList;
            if (selectedFiles.length === 1) {
                const file = selectedFiles[0];
                this.uploadFile(file, supportedFileTypes)
                    .pipe(tap(subject))
                    .subscribe();
            }
            else {
                subject.next(undefined);
                subject.complete();
            }
        };
        input.click();

        return subject.asObservable();
    }

    public uploadFile(file: File, supportedFileTypes: MediaMimeType[] = [MediaMimeType.JPG, MediaMimeType.PNG]): Observable<UploadInfo> {
        const subject = new Subject<UploadInfo>();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onprogress = (progress: ProgressEvent<FileReader>) => {
            if (progress.loaded < progress.total) {
                subject.next(<UploadInfo> {
                    status: UploadStatus.Loading,
                    progress: Math.round(progress.loaded / progress.total * 100),
                    file: undefined
                });
            }
        };
        reader.onloadstart = (progress: ProgressEvent<FileReader>) => {
            subject.next(<UploadInfo> {
                status: UploadStatus.Loading,
                progress: 0,
                file: undefined
            });
        };
        reader.onloadend = () => {
            if (reader.result) {
                const regex = /data:.*base64,/;
                let data = reader.result as string;
                if (this.isMimeTypeValid(data, supportedFileTypes)) {
                    data = data.replace(regex, '');

                    const fileModel = <FileModel> {
                        fileName: file.name,
                        content: data,
                        mimeType: file.type
                    };
                    subject.next(<UploadInfo> {
                        status: UploadStatus.Success,
                        progress: 100,
                        file: fileModel
                    });
                }
                else {
                    subject.next(<UploadInfo> {
                        status: UploadStatus.Error,
                        progress: 0,
                        file: undefined,
                        errorMessage: 'Niepoprawny format pliku. Wybierz inny plik.'
                    });
                }
                subject.complete();
            }
        };
        reader.onerror = () => {
            subject.next(<UploadInfo> {
                status: UploadStatus.Error,
                progress: 0,
                file: undefined,
                errorMessage: 'Błąd przesyłania pliku. Spróbuj ponownie.'
            });
            subject.complete();
        };
        reader.onabort = () => {
            subject.next(<UploadInfo> {
                status: UploadStatus.Aborted,
                progress: 0, // not important to have here real progress
                file: undefined,
                errorMessage: 'Przesyłanie pliku zostało anulowane.'
            });
            subject.complete();
        };
        return subject.asObservable();
    }

    public getFileUrl(file: FileModel): string {
        return `data:${file.mimeType};base64,${file.content}`;
    }

    public sendToApi(file: FileModel): Observable<FileModel | undefined> {
        return this.media.createImage(file)
                   .pipe(
                       map(response => {
                           if (!response.error) {
                               file.id = response.createdId as string;
                               return file;
                           }
                           return undefined;
                       })
                   );
    }

    private isMimeTypeValid(base64Content: string, supportedFileTypes: MediaMimeType[]): boolean {
        const regex = new RegExp('data:(.*);base64,');
        const match = base64Content.match(regex);
        if (match) {
            const mimeType = match[1];
            return supportedFileTypes.includes(mimeType as MediaMimeType);
        }
        return false; // no mime type found, invalid
    }
}


export interface UploadInfo {
    status: UploadStatus;
    progress: number;
    file: FileModel | undefined;
    errorMessage?: string;
}

export enum UploadStatus {
    Loading = 1,
    Success,
    Error,
    Aborted
}
