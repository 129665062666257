import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralResponse} from '../models';
import {SubscriptionsResponse} from '../models/api/subscriptions/subscriptions.response';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class ApiSubscriptionsService {

    constructor(private readonly http: HttpClient,
                private readonly config: ConfigService) {
    }

    /**
     * Creates new email subscription.
     * @param email Email address for subscription.
     */
    public createSubscription(email: string): Observable<GeneralResponse> {
        return this.http.post<GeneralResponse>(
            `${this.config.apiUrl}/subscriptions`, {email}
        );
    }

    /**
     * Returns a set of subscriptions sorted by date of creation.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param count Number of records that are returned in each page / batch.
     * @param pageIndex Current page index to fetch.
     * @param sorting A sorting order of subscription by date of creation.
     */
    public getSubscriptions(
        count: number = 30,
        pageIndex: number = 0,
        sorting: 'asc' | 'desc' = 'desc'
    ): Observable<SubscriptionsResponse> {
        return this.http.get<SubscriptionsResponse>(
            `${this.config.apiUrl}/subscriptions?pageSize=${count}&pageIndex=${pageIndex}&sort=${sorting}`
        );
    }
}
