import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {
    DevToolsComponent,
    LoginComponent,
    AnimatedCirclesComponent,
    FileUploaderComponent,
    PopupComponent, PopupService
} from './components';
import {CommonLibConfig} from './models';
import {
    ApiAuthService,
    ApiTrainingsService,
    ApiUsersService,
    ApiSubscriptionsService,
    AuthService,
    ConfigService,
    IdentityInterceptor,
    WindowService,
    DomObserverService
} from './services';
import {DragAndDropDirective} from './directives/drag-and-drop.directive';

@NgModule({
    declarations: [
        AnimatedCirclesComponent,
        DevToolsComponent,
        LoginComponent,
        FileUploaderComponent,
        DragAndDropDirective,
        PopupComponent
    ],
    imports: [HttpClientModule, CommonModule, BrowserModule, ReactiveFormsModule, FormsModule],
    exports: [AnimatedCirclesComponent, DevToolsComponent, LoginComponent, FileUploaderComponent, PopupComponent]
})
export class CommonLibModule {
    constructor(@Optional() @SkipSelf() parentModule?: CommonLibModule) {
        if (parentModule) {
            throw new Error('CommonLibModule is already loaded. Import it once in the AppModule only.');
        }
    }

    /**
     * Initializes Common Library Module
     * @param config A configuration required for the library.
     */
    public static forRoot(config: CommonLibConfig): ModuleWithProviders<CommonLibModule> {
        return {
            ngModule: CommonLibModule,
            providers: [
                {provide: CommonLibConfig, useValue: config || new CommonLibConfig()},
                ApiAuthService,
                ApiTrainingsService,
                ApiUsersService,
                ApiSubscriptionsService,
                AuthService,
                WindowService,
                ConfigService,
                DomObserverService,
                PopupService,
                {provide: HTTP_INTERCEPTORS, useClass: IdentityInterceptor, multi: true}
            ]
        };
    }
}
