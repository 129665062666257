<div class="login-screen container-fluid" [class.sign-in-succeed]="loginSucceed">
    <ip-animated-circles></ip-animated-circles>
    <div class="background-logo-figure">
        <div class="row">
            <div class="col-4 offset-4">
                <div class="figure-wrapper">
                    <div class="figure"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="background-figure">
        <div class="header">
            <div class="img-wrapper">
                <svg class="img-waves" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 1920 118.8856">
                    <g>
                        <path
                            d="M0,55.35c46.8335-14.082,58.0851-39.1286,150.4512-34.4624S311.1919-4.134,382.7431.491,596.02,72.4941,659.1151,70.4824s184.4431-30.584,280.2061-25.0153,164.7123,35.0738,292.2035,37.0855,105.0533-9.272,163.1817-31.9735,135.2818-28.35,212.0367-12.9273S1897.2337,102.284,1920,102.9545v15.9311H0Z"
                            fill="#ffffff" fill-opacity="0.5"></path>
                        <path
                            d="M1920,41.3413c-93.7982,3.6583-172.933,17.886-245.5936,21.512s-227.89,19.3977-291.9633,17.8205S1195.14,56.6962,1097.8927,61.062,930.6266,88.56,801.1587,90.1369,633.0653,63.6347,537.1834,60.0978c-81.1193-2.9922-144.2449,10.0615-222.19,22.1532S23.1193,45.9918,0,46.5175v72.3681H1920Z"
                            fill="#ffffff" fill-opacity="0.8"></path>
                        <path
                            d="M1920,41.3413C1903.936,24.994,1867.18,10.85,1837.013,14.35c-54.0387,6.27-56.407,14.93-98.3935,19.5553S1606.9348,58.6477,1569.91,56.636s-108.2317-30.584-164.4256-25.0153-96.6536,35.0738-171.4658,37.0855-61.6455-9.272-95.7555-31.9735S1058.88,8.3823,1013.84,23.8054,824.6519,93.5174,811.2926,94.188s5.7255,21.4582,5.7255,21.4582L1920,118.8856Z"
                            fill="#ffffff" fill-opacity="0.5"></path>
                        <path
                            d="M924.513,118.8856c-30.1332-16.2466-59.0579-55.5532-119.1-55.1779S707.8851,78.9868,661.3548,80.2872s-146.1295,14.308-187.0459,9.3687S355.1666,50.5305,292.9028,51.65s-107.5751,27.404-190.3661,23.496C21.37,71.3146,34.6222,61.8554,0,37.5488v81.3368Z"
                            fill="#ffffff" fill-opacity="0.5"></path>
                    </g>
                </svg>
            </div>
        </div>
    </div>
    <div class="foreground-logo-figure">
        <div class="row">
            <div class="col-4 offset-4">
                <div class="logo-wrapper">
                    <div class="logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.8181 490.8181">
                            <defs>
                                <style>.light-blue {
                                    fill: #29abe2;
                                }

                                .fig-style-1, .fig-style-4 {
                                    fill: #fff;
                                }

                                .fig-style-2, .fig-style-3 {
                                    fill: #0071bc;
                                }

                                .fig-style-2, .fig-style-3, .fig-style-4 {
                                    fill-rule: evenodd;
                                }

                                .fig-style-2 {
                                    opacity: 0.6;
                                }</style>
                            </defs>
                            <g id="Layer_2">
                                <g id="Browser">
                                    <circle class="light-blue" cx="245.409" cy="245.409" r="245.409"></circle>
                                    <g opacity="0.5">
                                        <path class="fig-style-1" fill="#ffffff"
                                              d="M245.409,16a229.4737,229.4737,0,0,1,89.2915,440.7964A229.4725,229.4725,0,0,1,156.1176,34.0215,227.9609,227.9609,0,0,1,245.409,16m0-16C109.8733,0,0,109.8736,0,245.4092S109.8733,490.8179,245.409,490.8179,490.818,380.9448,490.818,245.4092,380.9447,0,245.409,0Z"></path>
                                    </g>
                                    <path class="fig-style-2"
                                          d="M488.9451,275.8025l-63.8819-63.8819L279.1,245.8057l-40.3667,43.758L219.32,394.5434l88.3082,88.3082C403.52,457.793,476.5437,376.1921,488.9451,275.8025Z"></path>
                                    <rect class="fig-style-1" x="331" y="184.3872" width="17.0566" height="108.6997"
                                          transform="translate(56.4204 542.2575) rotate(-82.1056)"></rect>
                                    <path class="fig-style-1"
                                          d="M289.2509,225.9928l102.1275,14.1389L389.8055,251.48,287.678,237.3415l1.5729-11.3487m-4.7764-6.3126-.7685,5.5441-1.5729,11.3487-.7687,5.5458,5.5459.7678,102.1275,14.1387,5.5442.7676.7684-5.5441L396.9233,240.9l.7686-5.5457-5.5459-.7679L290.0186,220.4478l-5.5441-.7676Z"></path>
                                    <rect class="fig-style-1" x="234.5224" y="279.1068" width="17.0533" height="81.8328"
                                          transform="translate(-37.6371 32.442) rotate(-7.0891)"></rect>
                                    <path class="fig-style-1"
                                          d="M244.0329,281.4917l9.4,75.6505-11.3674,1.4121-9.4006-75.6506,11.3676-1.412m4.8649-6.2453-5.555.69-11.3676,1.412-5.5555.69.69,5.5555,9.4005,75.65.69,5.555,5.555-.69,11.3675-1.4121,5.5555-.69-.69-5.5555-9.4005-75.6505-.69-5.555Z"></path>
                                    <rect class="fig-style-1" x="164.3141" y="119.9549" width="17.0527" height="66.5553"
                                          transform="translate(-58.0052 145.2134) rotate(-39.5692)"></rect>
                                    <path class="fig-style-1"
                                          d="M157.8421,126.0887,196.67,173.0806l-8.831,7.2956-38.8279-46.9918,8.831-7.2957m.75-7.8808-4.3154,3.565-8.8311,7.2957-4.316,3.5657,3.566,4.3157,38.828,46.9919,3.5653,4.315,4.3154-3.565,8.831-7.2956,4.316-3.5656-3.566-4.3158L162.1575,122.523l-3.5653-4.3151Z"></path>
                                    <path class="fig-style-3"
                                          d="M390.6231,291.3639a45.5589,45.5589,0,1,1,27.7095-9.422A45.2631,45.2631,0,0,1,390.6231,291.3639Z"></path>
                                    <path class="fig-style-1"
                                          d="M390.5609,203.0467a42.76,42.76,0,1,1-26.0052,8.8434,42.6822,42.6822,0,0,1,26.0052-8.8434m.0026-5.598h0a48.3581,48.3581,0,1,0,38.3848,18.912,47.9179,47.9179,0,0,0-38.3848-18.912Z"></path>
                                    <path class="fig-style-3"
                                          d="M247.78,401.513a43.6665,43.6665,0,1,1,26.5588-9.031A43.3839,43.3839,0,0,1,247.78,401.513Z"></path>
                                    <path class="fig-style-1"
                                          d="M247.72,316.9806a40.8675,40.8675,0,1,1-24.8546,8.4522,40.7938,40.7938,0,0,1,24.8546-8.4522m.0026-5.5979h0a46.4658,46.4658,0,1,0,36.8826,18.1719,46.0433,46.0433,0,0,0-36.8826-18.1719Z"></path>
                                    <path class="fig-style-3"
                                          d="M153.4462,159.0238a29.2887,29.2887,0,1,1,17.8134-6.0585A29.0968,29.0968,0,0,1,153.4462,159.0238Z"></path>
                                    <path class="fig-style-1"
                                          d="M153.4074,103.2481a26.4881,26.4881,0,1,1-16.1094,5.4782,26.44,26.44,0,0,1,16.1094-5.4782m.0016-5.598h0a31.7964,31.7964,0,0,0-19.52,6.6358,32.0857,32.0857,0,0,0,19.5567,57.536,31.7915,31.7915,0,0,0,19.5182-6.6358A32.0857,32.0857,0,0,0,153.409,97.65Z"></path>
                                    <path class="fig-style-3"
                                          d="M231.1011,254.5236a30.8061,30.8061,0,1,1,18.7345-6.3715A30.5971,30.5971,0,0,1,231.1011,254.5236Z"></path>
                                    <path class="fig-style-1"
                                          d="M231.0606,195.7208a28.0019,28.0019,0,1,1-17.03,5.7913,27.9516,27.9516,0,0,1,17.03-5.7913m.0018-5.5979h0a33.2956,33.2956,0,0,0-20.44,6.9489,33.5993,33.5993,0,0,0,20.4794,60.25,33.5995,33.5995,0,0,0-.0389-67.1989Z"></path>
                                    <path class="fig-style-4"
                                          d="M231.1285,292.3643a68.4767,68.4767,0,1,1,.0027,0Zm-.0861-119.7023A50.5748,50.5748,0,0,0,199.99,183.2237a51.06,51.06,0,0,0,31.13,91.56,51.0607,51.0607,0,0,0-.0779-102.1213Z"></path>
                                    <path class="fig-style-1"
                                          d="M231.0334,157.881A65.8422,65.8422,0,1,1,190.99,171.4984a65.733,65.733,0,0,1,40.0436-13.6174m.0868,119.7a53.8584,53.8584,0,1,0-42.7587-21.0638A53.6268,53.6268,0,0,0,231.12,277.5812m-.0868-125.2982a70.7757,70.7757,0,0,0-43.4524,14.7751,71.4389,71.4389,0,0,0,43.5478,128.1037,71.4394,71.4394,0,0,0-.0954-142.8788Zm.0867,119.7a48.2615,48.2615,0,1,1,29.3472-9.9816,47.9537,47.9537,0,0,1-29.3472,9.9816Z"></path>
                                    <polygon class="fig-style-1"
                                             points="265.994 181.845 304.964 148.227 316.289 160.977 277.322 194.595 265.994 181.845"></polygon>
                                    <path class="fig-style-1"
                                          d="M304.7019,152.1475l7.6093,8.5652-34.729,29.9612-7.6094-8.5652,34.7291-29.9612m.5207-7.8425-4.1775,3.6039L266.3162,177.87l-4.3007,3.71,3.7724,4.2462,7.6093,8.5653,3.6643,4.1246,4.1774-3.6039,34.729-29.9612,4.3007-3.71-3.7724-4.2462-7.6094-8.5652-3.6642-4.1246Z"></path>
                                    <polygon class="fig-style-1"
                                             points="121.876 241.28 171.913 229.234 176.145 245.758 126.11 257.804 121.876 241.28"></polygon>
                                    <path class="fig-style-1"
                                          d="M169.886,232.6025l2.8432,11.0987L128.1361,254.436l-2.8431-11.0987,44.593-10.7348m4.0538-6.7338-5.3639,1.2913-44.5931,10.7348-5.5221,1.3293,1.4094,5.5023,2.8432,11.0987,1.369,5.3446,5.3639-1.2912,44.5931-10.7348,5.5222-1.3294-1.41-5.5022-2.8431-11.0988-1.3691-5.3446Z"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-logo-figure">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445.0522 149.3929">
            <defs>
                <style>.cls-1 {
                    font-size: 44.1381px;
                    font-family: Roboto-Light, Roboto;
                    font-weight: 300;
                }

                .cls-1, .cls-2, .cls-3 {
                    fill: #fff;
                }

                .cls-3 {
                    fill-rule: evenodd;
                }</style>
            </defs>
            <g id="Layer_2">
                <g id="Browser">
                    <text class="cls-1" transform="translate(0 37.7588) scale(1.05 1)">Platforma edukacyjna</text>
                    <circle class="cls-2" cx="107.4535" cy="101.6897" r="8.8921"></circle>
                    <circle class="cls-2" cx="340.4129" cy="101.6897" r="8.8921"></circle>
                    <path class="cls-3"
                          d="M442.53,81.116a6.359,6.359,0,1,1-6.3611-6.35A6.3748,6.3748,0,0,1,442.53,81.116Z"></path>
                    <path class="cls-3"
                          d="M370.3847,60.5007a6.5239,6.5239,0,1,1-6.5261-6.5143A6.54,6.54,0,0,1,370.3847,60.5007Z"></path>
                    <path class="cls-3"
                          d="M323.425,60.5007a6.4919,6.4919,0,1,1-6.4942-6.5143A6.5245,6.5245,0,0,1,323.425,60.5007Z"></path>
                    <path class="cls-3"
                          d="M293.6468,60.533a6.524,6.524,0,1,1-6.5262-6.5466A6.5566,6.5566,0,0,1,293.6468,60.533Z"></path>
                    <path class="cls-3"
                          d="M244.5551,141.393a6.5564,6.5564,0,1,1-6.5586-6.579A6.5891,6.5891,0,0,1,244.5551,141.393Z"></path>
                    <path class="cls-3"
                          d="M213.0977,80.4441a6.2663,6.2663,0,1,1-12.5312-.0681,6.2089,6.2089,0,0,1,6.2629-6.22A6.2713,6.2713,0,0,1,213.0977,80.4441Z"></path>
                    <path class="cls-3"
                          d="M64.9182,82.6665a6.2985,6.2985,0,1,1-12.5958,0,6.2979,6.2979,0,1,1,12.5958,0Z"></path>
                    <path class="cls-3" d="M64.9182,119.3a6.3305,6.3305,0,1,1-12.66,0,6.33,6.33,0,1,1,12.66,0Z"></path>
                    <path class="cls-3"
                          d="M395.9,120.5188a6.368,6.368,0,1,1-6.3791-6.3443A6.3948,6.3948,0,0,1,395.9,120.5188Z"></path>
                    <path class="cls-2"
                          d="M130.32,62.0608Q121.6315,52.63,107.163,52.63q-14.2762,0-22.7694,9.4308-8.495,9.4314-8.4946,26.0961v25.8376q0,16.7312,8.4623,26.0643,8.4609,9.3347,22.8017,9.3341,14.534,0,23.1891-9.3341t8.6559-26.0643V88.1569Q139.008,71.4915,130.32,62.0608Zm-4.36,51.9337q0,12.4683-4.8767,18.8294-4.8782,6.3642-13.92,6.3631-8.7852,0-13.5326-6.3954t-4.7476-18.7971V87.9629q0-12.2718,4.7154-18.6355,4.7142-6.3611,13.5648-6.3621,8.9771,0,13.8877,6.3621,4.9084,6.3635,4.909,18.6355Z"></path>
                    <polygon class="cls-2"
                             points="293.647 148.036 280.599 148.036 244.943 78.08 244.555 78.21 244.555 141.393 231.442 141.393 231.442 53.986 244.555 53.986 280.211 123.749 280.599 123.62 280.599 60.517 293.647 60.517 293.647 148.036"></polygon>
                    <path class="cls-2"
                          d="M429.941,124.33a15.932,15.932,0,0,0-3.7465-10.9163q-3.747-4.2645-13.1128-7.4929-13.4357-4.0633-20.6054-10.6135-7.17-6.5478-7.17-17.3223A23.3471,23.3471,0,0,1,393.2836,59.76q7.977-7.1291,20.5089-7.1295,13.0469,0,21.0254,8.2356a26.6158,26.6158,0,0,1,7.7191,19.8629l-.0074.3875H429.8117q0-8.2311-4.2309-13.3849t-11.9176-5.1538q-7.3,0-11.2718,4.2957a15.4963,15.4963,0,0,0-3.9724,10.9485,13.4735,13.4735,0,0,0,4.2632,10.1091q4.2633,4.1028,14.0815,7.4607,12.8534,4.01,19.5722,10.93,6.7172,6.92,6.7179,17.9144,0,11.5764-7.9452,18.3668t-20.8641,6.7905a34.3367,34.3367,0,0,1-21.9944-7.4606c-6.2657-4.9745-9.2915-12.3693-9.0754-21.4122l.129-.0013H395.9q0,9.6567,5.0706,14.2914,5.07,4.6363,13.2741,4.6348,7.3636,0,11.53-4.1016T429.941,124.33Z"></path>
                    <path class="cls-2"
                          d="M357.3369,60.5165v58.6459q0,10.4638-4.5864,15.3737a14.6582,14.6582,0,0,1-5.8638,3.8209v10.4922a28.582,28.582,0,0,0,15.1334-7.2727q8.364-7.8138,8.3646-22.4141V60.5165Z"></path>
                    <path class="cls-2"
                          d="M333.94,138.4108a14.47,14.47,0,0,1-5.9933-4.0682q-4.5222-5.1035-4.5214-15.18V60.5165H310.4412v58.6459q0,14.5981,8.2038,22.4141a27.934,27.934,0,0,0,15.2947,7.3447Z"></path>
                    <path class="cls-2"
                          d="M164.587,97.1017v-10.39q0-11.7751,4.974-17.9542t13.5647-6.18q8.5266,0,12.8221,4.62t4.6187,13.467h12.4023l.1289-.3875q-.5177-13.1123-8.1388-20.3794Q197.3362,52.63,182.9322,52.63q-14.2112,0-22.8021,8.9788t-8.5909,25.3206V97.1017Z"></path>
                    <path class="cls-2"
                          d="M184.2887,100.43v9.4953h16.0838v21.3812a14.6442,14.6442,0,0,1-5.5227,5.8461q-3.7149,2.2932-10.4321,2.2927-9.1728,0-14.5015-6.3077t-5.3292-18.3437v-4.7453H151.5392v4.7866q0,16.6012,9.0434,25.5786,9.0421,8.9794,23.8351,8.9794,11.3036,0,18.571-4.5219,7.2666-4.52,10.4965-10.2059V100.43Z"></path>
                    <path class="cls-2"
                          d="M52.2574,119.4859q-.13,10.4638-3.9077,15.2115T36.4321,139.445A14.7253,14.7253,0,0,1,23.61,132.91q-4.554-6.5336-4.5542-18.8275v-4.0338H6.0726v4.0762q0,16.6647,8.2679,25.9665t22.0916,9.3019q13.5644,0,20.9932-7.72,7.4268-7.7172,7.4929-21.8l-.0036-.387Z"></path>
                    <path class="cls-2"
                          d="M19.0559,97.1017V87.7472q0-12.0995,4.4574-18.6344a14.307,14.307,0,0,1,12.5308-6.5355q8.3331,0,12.2083,4.8762t4.07,15.213H64.9182V82.279q-.1935-14.5341-7.6224-22.0917T36.0441,52.63q-13.6293,0-21.8,9.3663T6.0726,88.0279v9.0738Z"></path>
                </g>
            </g>
        </svg>
        <div *ngIf="showProgressbar" class="loading-progress">
            <div class="progress-value text-center">{{progressBarPercent}}%</div>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width.%]="progressBarPercent"
                     [attr.aria-valuenow]="progressBarPercent" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
    <div class="form">
        <div class="row mt-xxxl-5">
            <div
                class="form-container ms-auto me-auto ps-5 pe-5 ps-md-5 col-12 col-md-6 mt-md-4 col-lg-5 col-xl-4 col-xxl-3 offset-xxl-1 me-md-0">
                <h1 class="title text-center text-md-start pt-4 text-light">Logowanie</h1>
                <form class="content" [formGroup]="form">
                    <div class="row login-row" [class.error]="loginControl.errors">
                        <div class="col-12">
                            <label for="login">Nazwa użytkownika</label>
                            <input formControlName="login" autocomplete="off" class="w-100" id="login" type="text"
                                   placeholder="np. jan-kowalski">
                            <div *ngIf="loginControl.hasError('required')"
                                 class="error-msg small text-danger text-end">Login jest wymagany.
                            </div>
                            <div *ngIf="loginControl.hasError('invalidLogin')"
                                 class="error-msg small text-danger text-end">Login jest niepoprawny.
                            </div>
                        </div>
                    </div>
                    <div class="row password-row" [class.error]="passwordControl.errors">
                        <div class="col-12">
                            <label for="password">Hasło</label>
                            <input formControlName="password" autocomplete="off" class="w-100" id="password"
                                   type="password">
                            <div *ngIf="passwordControl.hasError('required')"
                                 class="error-msg small text-danger text-end">Hasło jest wymagane.
                            </div>
                            <div *ngIf="passwordControl.hasError('invalidPassword')"
                                 class="error-msg small text-danger text-end">Hasło jest niepoprawne.
                            </div>
                        </div>
                    </div>
                    <div class="row login-btn-row">
                        <div class="col-12 error">
                            <a (click)="onLogin()" class="btn btn-action w-100 mt-4"
                               [class.in-progress]="loggingInProgress">
                                Zaczynamy
                                <span class="spinner-border spinner-border-sm progress-icon" role="status"
                                      aria-hidden="true"></span>
                            </a>
                            <div *ngIf="serverError" class="error-msg small text-danger text-center mt-n2">Logowanie nie
                                powiodło się (#{{serverError}}).
                            </div>
                        </div>
                    </div>
                    <div class="row links-row">
                        <div class="col-6">
                            <a class="btn-link" disabled="disabled" href="/login">Nie mam jeszcze konta</a>
                        </div>
                        <div class="col-6 text-end">
                            <a class="btn-link" disabled="disabled" href="/login">Nie pamiętam hasła</a>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Form right column alignment -->
            <div class="col-md-6 mt-md-8 col-lg-7 col-xl-8"></div>
        </div>
    </div>
</div>
