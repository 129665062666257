import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ConfigService} from './config.service';
import {WindowService} from './window.service';

@Injectable({providedIn: 'root'})
export class AuthService {
    private tokenStorageKey = 'cognus-token';

    /**
     * Current session token.
     */
    public get token(): string | null {
        return this.window.localStorage.getItem(this.tokenStorageKey);
    }

    constructor(
        private readonly http: HttpClient,
        private readonly config: ConfigService,
        private readonly window: WindowService
    ) {

    }

    /**
     * Authenticate the user and stores a session token.
     * @param login User login.
     * @param password User password.
     */
    public login(login: string, password: string): Observable<string> {
        const body = {
            login, password
        };
        return this.http
                   .post<{ token: string, result: string }>(`${this.config.apiUrl}/auth`, body)
                   .pipe(
                       // processing for successful auth
                       map(response => response.token),
                       tap((token) => this.setToken(token))
                   );
    }

    /**
     * Clears our current session and token, locally and on the server.
     * NOTE: Server token may fail to be cleared, but it doesn't matter, that's
     * why we ignore any call errors.
     */
    public logout(): Observable<any> {
        if (this.token) {
            return this.http
                       .post(`${this.config.apiUrl}/auth/logout`, {}, {
                           headers: {
                               authorization: `JWT ${this.token}`
                           }
                       })
                       .pipe(
                           tap(() => this.removeToken()),
                           catchError(() => {
                               this.removeToken();
                               return of({});
                           })
                       );
        }

        this.removeToken(); // placeholder, removing non existing token
        return of({});
    }

    /**
     * Verifies current session, invalid session result (false) should
     * result in redirection to login screen.
     */
    public isCurrentSessionValid(): Observable<boolean> {
        if (this.token) {
            // validate token
            return this.http
                       .post(`${this.config.apiUrl}/auth/validate`, {}, {
                           headers: {
                               authorization: `JWT ${this.token}`
                           }
                       })
                       .pipe(
                           map(() => true),
                           catchError(error => {
                               if (error instanceof HttpErrorResponse && error.status === 401) {
                                   this.removeToken();
                                   return of(false);
                               }
                               return throwError(error);
                           })
                       );
        }
        return of(false);
    }

    private removeToken(): void {
        this.window.localStorage.removeItem(this.tokenStorageKey);
    }

    private setToken(token: string): void {
        this.window.localStorage.setItem(this.tokenStorageKey, token);
    }
}
