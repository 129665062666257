import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralResponse, TrainingModel, TrainingResponse, TrainingsResponse} from '../models';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTrainingsService {
    constructor(private readonly http: HttpClient, private readonly config: ConfigService) {

    }

    /**
     * Get a full single training record, with all its steps.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param id Id of the training to fetch.
     */
    public getTrainingById(id: string): Observable<TrainingResponse> {
        return this.http.get<TrainingResponse>(`${this.config.apiUrl}/trainings/${id}`);
    }

    /**
     * Returns a set of trainings sorted by creation date. Each training contains only general data, without details like steps.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param count Number of records that are returned in each page / batch.
     * @param pageIndex Current page index to fetch.
     * @param sorting A sorting order of trainings by creation date. By default from the newest records to the oldest records.
     */
    public getTrainings(
        count: number = 30,
        pageIndex: number = 0,
        sorting: 'asc' | 'desc' = 'desc'
    ): Observable<TrainingsResponse> {
        return this.http.get<TrainingsResponse>(
            `${this.config.apiUrl}/trainings?pageSize=${count}&pageIndex=${pageIndex}&sort=${sorting}`
        );
    }

    /**
     * Returns a set of given user trainings sorted by creation date (onlu trainings assigned to the user account).
     * Each training contains only general data, without details like steps.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId User ID
     * @param count Number of records that are returned in each page / batch.
     * @param pageIndex Current page index to fetch.
     * @param sorting A sorting order of trainings by creation date. By default from the newest records to the oldest records.
     */
    public getUserTrainings(
        userId: string,
        count: number = 30,
        pageIndex: number = 0,
        sorting: 'asc' | 'desc' = 'desc'
    ): Observable<TrainingsResponse> {
        return this.http.get<TrainingsResponse>(
            `${this.config.apiUrl}/users/${userId}/trainings?pageSize=${count}&pageIndex=${pageIndex}&sort=${sorting}`
        );
    }

    /**
     * Archives the training record.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param trainingId Id of the training record.
     */
    public archiveTraining(trainingId: string): Observable<GeneralResponse> {
        return this.archiveUnarchiveTraining(trainingId, true);
    }

    /**
     * Unarchvies the training record.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param trainingId Id of the training record.
     */
    public unarchiveTraining(trainingId: string): Observable<GeneralResponse> {
        return this.archiveUnarchiveTraining(trainingId, false);
    }

    /**
     * Creates a new training record. If request contains id it will be ignored. New record id is returned in
     * response.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param data A new training to create.
     */
    public createTraining(data: TrainingModel): Observable<GeneralResponse> {
        return this.http.post<GeneralResponse>(`${this.config.apiUrl}/trainings`, data);
    }

    /**
     * Permanently removes a new training record, operation cannot be undone.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param trainingId Id of the training record to remove.
     */
    public deleteTraining(trainingId: string): Observable<GeneralResponse> {
        return this.http.delete<GeneralResponse>(`${this.config.apiUrl}/trainings/${trainingId}`);
    }

    private archiveUnarchiveTraining(trainingId: string, archive: boolean): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/trainings/${trainingId}?archive=${archive}`, {});
    }
}
