import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {

    constructor(private readonly auth: AuthService) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: this.getHeaders(),
            withCredentials: false // not using cookies
        });
        return next.handle(request);
    }

    private getHeaders(): any {
        let headers: any = {
            'Content-Type': 'application/json; charset=utf-8'
        };
        if (this.auth.token) {
            headers['Authorization'] = `JWT ${this.auth.token}`;
        }
        return headers;

    }
}
