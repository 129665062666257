import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {PopupService} from './popup.service';

@Component({
    selector: 'ip-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public id!: string;
    @ViewChild('popupMenu', {static: true}) public menuRef!: ElementRef;
    public visible: boolean = false;

    constructor(private renderer: Renderer2, private popupService: PopupService) {

    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        const rect = this.getParent().getBoundingClientRect();
        const x = rect.x + rect.width;
        const y = rect.y + rect.height;
        this.renderer.setStyle(this.menuRef.nativeElement, 'left', x + 'px');
        this.renderer.setStyle(this.menuRef.nativeElement, 'top', y + 'px');
        this.popupService.register(this);
    }

    public ngOnDestroy(): void {
        this.popupService.unregister(this);
    }

    public onBackdropClick(event: MouseEvent): void {
        event.stopPropagation();
        this.popupService.hide(this.id);
    }

    private getParent(): HTMLElement {
        return this.menuRef.nativeElement.parentElement?.parentElement as HTMLElement;
    }


}
