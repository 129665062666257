/**
 * Defines an action that can be executed on interactive objects.
 */
export enum ObjectActionType {
    GoToStep = 'GO_TO_STEP', // navigate to other step
    ShowTooltip = 'TOOLTIP', // show tooltip attached to interactive object

    // ---- Not supported yet ----
    ExecuteAgent = 'AGENT', // execute action on agent character  NOT SUPPORTED YET
    ImageReplace = 'REPLACE_IMG' // replaces existing static image with other one NOT SUPPORTED YET
}
