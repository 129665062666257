/**
 * Defines an OS type - mostly related to target OS for training.
 */
export enum OperatingSystem {
    Windows = 'WINDOWS',
    Mac = 'MAC',
    Linux = 'LINUX',
    Android = 'ANDROID',
    IOS = 'IOS',
    NA = 'N/A'
}
