import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ip-animated-circles',
    templateUrl: './animated-circles.component.html',
    styleUrls: ['./animated-circles.component.scss']
})
export class AnimatedCirclesComponent implements OnInit {

    @Input()
    public coverRelativeParent: boolean = true;

    constructor() {
    }

    public ngOnInit(): void {
    }

}
