export class CommonLibConfig {
    public apiUrl: string = '';
    public filesUrl: string = '';
    public environment: AppEnvironment = AppEnvironment.Test;

    constructor(init?: Partial<CommonLibConfig>) {
        Object.assign(this, init);
    }
}

export enum AppEnvironment {
    Production = 'Production',
    Test = 'Test'
}
