import {Injectable} from '@angular/core';

class LocalStorageSubstitute implements Storage {

    private static items: { [key: string]: string } = {};

    public get length() {
        return Object.keys(LocalStorageSubstitute.items).length;
    }

    public getItem(key: string) {
        return LocalStorageSubstitute.items[key];
    }

    public setItem(key: string, value: string) {
        return LocalStorageSubstitute.items[key] = value;
    }

    public removeItem(key: string) {
        delete LocalStorageSubstitute.items[key];
    }

    public clear() {
        for (const prop of Object.keys(LocalStorageSubstitute.items)) {
            delete LocalStorageSubstitute.items[prop];
        }
    }

    public key(index: number) {
        return Object.keys(LocalStorageSubstitute.items)[index];
    }
}

@Injectable()
export class WindowService {

    public get nativeWindow(): any {
        return window;
    }

    public get localStorage(): Storage {
        try {
            window.localStorage.setItem('__item', 'value');
            window.localStorage.removeItem('__item');
            return window.localStorage;
        }
        catch (e) {
            return <Storage> (new LocalStorageSubstitute());
        }
    }

    public open(url: string, target: string = '_blank'): void {
        window.open(url, target);
    }
}
