<div class="animated-circles" [class.fixed-full-screen]="coverRelativeParent">
    <div class="circle circle-1"></div>
    <div class="circle circle-2"></div>
    <div class="circle circle-3"></div>
    <div class="circle circle-4"></div>
    <div class="circle circle-5"></div>
    <div class="circle circle-6"></div>
    <div class="circle circle-7"></div>
    <div class="circle circle-8"></div>
    <div class="circle circle-9"></div>
    <div class="circle circle-10"></div>
    <div class="circle circle-11"></div>
    <div class="circle circle-12"></div>
    <div class="circle circle-13"></div>
    <div class="circle circle-14"></div>
    <div class="circle circle-15"></div>
    <div class="circle circle-16"></div>
    <div class="circle circle-17"></div>
</div>
