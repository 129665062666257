<svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1"
     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <symbol id="circle-logo" viewBox="0 0 490.8181 490.8181">
            <defs>
                <style>.light-blue {
                    fill: #29abe2;
                }

                .fig-style-1, .fig-style-4 {
                    fill: #fff;
                }

                .fig-style-2, .fig-style-3 {
                    fill: #0071bc;
                }

                .fig-style-2, .fig-style-3, .fig-style-4 {
                    fill-rule: evenodd;
                }

                .fig-style-2 {
                    opacity: 0.6;
                }</style>
            </defs>
            <g id="Layer_2">
                <g id="Browser">
                    <circle class="light-blue" cx="245.409" cy="245.409" r="245.409"></circle>
                    <g opacity="0.5">
                        <path class="fig-style-1" fill="#ffffff"
                              d="M245.409,16a229.4737,229.4737,0,0,1,89.2915,440.7964A229.4725,229.4725,0,0,1,156.1176,34.0215,227.9609,227.9609,0,0,1,245.409,16m0-16C109.8733,0,0,109.8736,0,245.4092S109.8733,490.8179,245.409,490.8179,490.818,380.9448,490.818,245.4092,380.9447,0,245.409,0Z"></path>
                    </g>
                    <path class="fig-style-2"
                          d="M488.9451,275.8025l-63.8819-63.8819L279.1,245.8057l-40.3667,43.758L219.32,394.5434l88.3082,88.3082C403.52,457.793,476.5437,376.1921,488.9451,275.8025Z"></path>
                    <rect class="fig-style-1" x="331" y="184.3872" width="17.0566" height="108.6997"
                          transform="translate(56.4204 542.2575) rotate(-82.1056)"></rect>
                    <path class="fig-style-1"
                          d="M289.2509,225.9928l102.1275,14.1389L389.8055,251.48,287.678,237.3415l1.5729-11.3487m-4.7764-6.3126-.7685,5.5441-1.5729,11.3487-.7687,5.5458,5.5459.7678,102.1275,14.1387,5.5442.7676.7684-5.5441L396.9233,240.9l.7686-5.5457-5.5459-.7679L290.0186,220.4478l-5.5441-.7676Z"></path>
                    <rect class="fig-style-1" x="234.5224" y="279.1068" width="17.0533" height="81.8328"
                          transform="translate(-37.6371 32.442) rotate(-7.0891)"></rect>
                    <path class="fig-style-1"
                          d="M244.0329,281.4917l9.4,75.6505-11.3674,1.4121-9.4006-75.6506,11.3676-1.412m4.8649-6.2453-5.555.69-11.3676,1.412-5.5555.69.69,5.5555,9.4005,75.65.69,5.555,5.555-.69,11.3675-1.4121,5.5555-.69-.69-5.5555-9.4005-75.6505-.69-5.555Z"></path>
                    <rect class="fig-style-1" x="164.3141" y="119.9549" width="17.0527" height="66.5553"
                          transform="translate(-58.0052 145.2134) rotate(-39.5692)"></rect>
                    <path class="fig-style-1"
                          d="M157.8421,126.0887,196.67,173.0806l-8.831,7.2956-38.8279-46.9918,8.831-7.2957m.75-7.8808-4.3154,3.565-8.8311,7.2957-4.316,3.5657,3.566,4.3157,38.828,46.9919,3.5653,4.315,4.3154-3.565,8.831-7.2956,4.316-3.5656-3.566-4.3158L162.1575,122.523l-3.5653-4.3151Z"></path>
                    <path class="fig-style-3"
                          d="M390.6231,291.3639a45.5589,45.5589,0,1,1,27.7095-9.422A45.2631,45.2631,0,0,1,390.6231,291.3639Z"></path>
                    <path class="fig-style-1"
                          d="M390.5609,203.0467a42.76,42.76,0,1,1-26.0052,8.8434,42.6822,42.6822,0,0,1,26.0052-8.8434m.0026-5.598h0a48.3581,48.3581,0,1,0,38.3848,18.912,47.9179,47.9179,0,0,0-38.3848-18.912Z"></path>
                    <path class="fig-style-3"
                          d="M247.78,401.513a43.6665,43.6665,0,1,1,26.5588-9.031A43.3839,43.3839,0,0,1,247.78,401.513Z"></path>
                    <path class="fig-style-1"
                          d="M247.72,316.9806a40.8675,40.8675,0,1,1-24.8546,8.4522,40.7938,40.7938,0,0,1,24.8546-8.4522m.0026-5.5979h0a46.4658,46.4658,0,1,0,36.8826,18.1719,46.0433,46.0433,0,0,0-36.8826-18.1719Z"></path>
                    <path class="fig-style-3"
                          d="M153.4462,159.0238a29.2887,29.2887,0,1,1,17.8134-6.0585A29.0968,29.0968,0,0,1,153.4462,159.0238Z"></path>
                    <path class="fig-style-1"
                          d="M153.4074,103.2481a26.4881,26.4881,0,1,1-16.1094,5.4782,26.44,26.44,0,0,1,16.1094-5.4782m.0016-5.598h0a31.7964,31.7964,0,0,0-19.52,6.6358,32.0857,32.0857,0,0,0,19.5567,57.536,31.7915,31.7915,0,0,0,19.5182-6.6358A32.0857,32.0857,0,0,0,153.409,97.65Z"></path>
                    <path class="fig-style-3"
                          d="M231.1011,254.5236a30.8061,30.8061,0,1,1,18.7345-6.3715A30.5971,30.5971,0,0,1,231.1011,254.5236Z"></path>
                    <path class="fig-style-1"
                          d="M231.0606,195.7208a28.0019,28.0019,0,1,1-17.03,5.7913,27.9516,27.9516,0,0,1,17.03-5.7913m.0018-5.5979h0a33.2956,33.2956,0,0,0-20.44,6.9489,33.5993,33.5993,0,0,0,20.4794,60.25,33.5995,33.5995,0,0,0-.0389-67.1989Z"></path>
                    <path class="fig-style-4"
                          d="M231.1285,292.3643a68.4767,68.4767,0,1,1,.0027,0Zm-.0861-119.7023A50.5748,50.5748,0,0,0,199.99,183.2237a51.06,51.06,0,0,0,31.13,91.56,51.0607,51.0607,0,0,0-.0779-102.1213Z"></path>
                    <path class="fig-style-1"
                          d="M231.0334,157.881A65.8422,65.8422,0,1,1,190.99,171.4984a65.733,65.733,0,0,1,40.0436-13.6174m.0868,119.7a53.8584,53.8584,0,1,0-42.7587-21.0638A53.6268,53.6268,0,0,0,231.12,277.5812m-.0868-125.2982a70.7757,70.7757,0,0,0-43.4524,14.7751,71.4389,71.4389,0,0,0,43.5478,128.1037,71.4394,71.4394,0,0,0-.0954-142.8788Zm.0867,119.7a48.2615,48.2615,0,1,1,29.3472-9.9816,47.9537,47.9537,0,0,1-29.3472,9.9816Z"></path>
                    <polygon class="fig-style-1"
                             points="265.994 181.845 304.964 148.227 316.289 160.977 277.322 194.595 265.994 181.845"></polygon>
                    <path class="fig-style-1"
                          d="M304.7019,152.1475l7.6093,8.5652-34.729,29.9612-7.6094-8.5652,34.7291-29.9612m.5207-7.8425-4.1775,3.6039L266.3162,177.87l-4.3007,3.71,3.7724,4.2462,7.6093,8.5653,3.6643,4.1246,4.1774-3.6039,34.729-29.9612,4.3007-3.71-3.7724-4.2462-7.6094-8.5652-3.6642-4.1246Z"></path>
                    <polygon class="fig-style-1"
                             points="121.876 241.28 171.913 229.234 176.145 245.758 126.11 257.804 121.876 241.28"></polygon>
                    <path class="fig-style-1"
                          d="M169.886,232.6025l2.8432,11.0987L128.1361,254.436l-2.8431-11.0987,44.593-10.7348m4.0538-6.7338-5.3639,1.2913-44.5931,10.7348-5.5221,1.3293,1.4094,5.5023,2.8432,11.0987,1.369,5.3446,5.3639-1.2912,44.5931-10.7348,5.5222-1.3294-1.41-5.5022-2.8431-11.0988-1.3691-5.3446Z"></path>
                </g>
            </g>
        </symbol>
        <symbol id="text-logo" viewBox="0 0 438.731 145.708">
            <g>
                <path
                    d="M2.6924,21.0347h8.6445q5.1826,0,8.0225-2.5a8.4566,8.4566,0,0,0,2.84-6.6812,8.44,8.44,0,0,0-2.84-6.6484q-2.84-2.5108-8.0225-2.5108H0V34.0737H2.6924Zm0-16.1421h8.6445q4.0957,0,6.1328,1.9936a6.7217,6.7217,0,0,1,2.0371,5.0108,6.6075,6.6075,0,0,1-2.0488,4.9677q-2.0478,1.9718-6.1211,1.9717H2.6924Z"
                    fill="#fff"/>
                <rect x="28.2646" y="0.4526" width="2.6924" height="33.6211" fill="#fff"/>
                <path
                    d="M48.042,20.7549a14.1584,14.1584,0,0,0-7.9082,1.9394,5.9383,5.9383,0,0,0-2.9307,5.1724,6.249,6.249,0,0,0,1.98,4.8491,7.9336,7.9336,0,0,0,5.5557,1.8106A10.7952,10.7952,0,0,0,53.45,30.2808a13.4428,13.4428,0,0,0,.1133,1.7456q.1142.8621.3847,2.0473h2.8291a14.48,14.48,0,0,1-.4863-2.3061,18.9634,18.9634,0,0,1-.1474-2.3921V17.91a6.7657,6.7657,0,0,0-2.4668-5.625,10.1686,10.1686,0,0,0-6.5176-1.961,10.5782,10.5782,0,0,0-6.709,2.0581,5.4928,5.4928,0,0,0-2.5,4.6661l.0449.1293,2.4893.0215a4.0028,4.0028,0,0,1,1.8662-3.3193,7.7812,7.7812,0,0,1,4.6963-1.3574,7.1009,7.1009,0,0,1,4.7177,1.4223A4.8563,4.8563,0,0,1,53.45,17.8667v2.8882Zm5.4082,6.853a7.8869,7.8869,0,0,1-3.2354,3.33,10.0691,10.0691,0,0,1-5.1591,1.3037,5.5658,5.5658,0,0,1-3.7911-1.1963,3.9178,3.9178,0,0,1-1.3691-3.0923,4.396,4.396,0,0,1,2.207-3.7177,10.3317,10.3317,0,0,1,5.8946-1.4981H53.45Z"
                    fill="#fff"/>
                <path
                    d="M73.9307,33.9443l-.4082-1.9179q-.8145.1288-1.4366.1831t-1.2783.0537a2.981,2.981,0,0,1-2.32-.959,4.8,4.8,0,0,1-.8711-3.2437V12.8667h5.3867V10.7544H67.6162v-5.97H64.8779v5.97H60.6465v2.1123h4.2314V28.0605q0,3.3625,1.4375,4.9141a5.2027,5.2027,0,0,0,4.0166,1.5518,12.7459,12.7459,0,0,0,1.9463-.1509A7.3208,7.3208,0,0,0,73.9307,33.9443Z"
                    fill="#fff"/>
                <path
                    d="M80.72,34.0737h2.7149V12.8667h5.8379V10.7544H83.4346V7.35A5.4881,5.4881,0,0,1,84.6113,3.6a4.28,4.28,0,0,1,3.3946-1.3364,10.6229,10.6229,0,0,1,1.3466.0752,10.9729,10.9729,0,0,1,1.1192.2051L90.8115.41a15.8662,15.8662,0,0,0-1.56-.3018A10.9556,10.9556,0,0,0,87.7334,0a6.8333,6.8333,0,0,0-5.17,1.9185A7.5164,7.5164,0,0,0,80.72,7.35v3.4048H76.6689v2.1123H80.72Z"
                    fill="#fff"/>
                <path
                    d="M103.3252,34.5264A10.0285,10.0285,0,0,0,111.167,31.24,12.176,12.176,0,0,0,114.12,22.78v-.711a12.1558,12.1558,0,0,0-2.9648-8.4482,11.0245,11.0245,0,0,0-15.7266,0,12.1553,12.1553,0,0,0-2.9648,8.4482v.711A12.1463,12.1463,0,0,0,95.4287,31.24,10.1128,10.1128,0,0,0,103.3252,34.5264ZM95.1787,22.0591a10.7107,10.7107,0,0,1,2.15-6.76,7.7907,7.7907,0,0,1,11.9258,0,10.6439,10.6439,0,0,1,2.1719,6.76v.71a10.691,10.691,0,0,1-2.16,6.8028,7.8416,7.8416,0,0,1-11.9375,0,10.72,10.72,0,0,1-2.15-6.8028Z"
                    fill="#fff"/>
                <path
                    d="M122.4932,17.9746a7.4808,7.4808,0,0,1,2.3418-3.7607,6.3314,6.3314,0,0,1,4.1982-1.3687l2.0137.1294.3623-2.4141a7.76,7.76,0,0,0-.8028-.1616,6.5762,6.5762,0,0,0-1.0078-.0752,7.4218,7.4218,0,0,0-4.2539,1.2066,8.4757,8.4757,0,0,0-2.8515,3.3623v-.2588l-.34-3.8794h-2.375V34.0737h2.7149Z"
                    fill="#fff"/>
                <path
                    d="M149.5244,14.3467q1.6171,1.8252,1.6172,6.0576V34.0737H153.88V20.0586q0-.13-.0117-.41c-.0078-.187-.0108-.331-.0108-.4321a8.7025,8.7025,0,0,1,2.127-4.8159A6.265,6.265,0,0,1,160.85,12.522a5.6574,5.6574,0,0,1,4.4463,1.7817q1.5968,1.7813,1.5957,6.1006V34.0737h2.7158V20.4746q0-5.1936-2.1279-7.6724a7.5269,7.5269,0,0,0-6.041-2.4785,8.6751,8.6751,0,0,0-4.99,1.39,8.9919,8.9919,0,0,0-3.1562,3.8466,7.0992,7.0992,0,0,0-2.7051-3.9438,8.1193,8.1193,0,0,0-4.6953-1.293,9.2521,9.2521,0,0,0-4.6728,1.1206,8.3641,8.3641,0,0,0-3.1338,3.168l-.25-3.8579h-2.4209V34.0737h2.7382V17.6616a7.36,7.36,0,0,1,2.3643-3.7466,6.9447,6.9447,0,0,1,4.56-1.393A5.6411,5.6411,0,0,1,149.5244,14.3467Z"
                    fill="#fff"/>
                <path
                    d="M186.2178,20.7549a14.1635,14.1635,0,0,0-7.91,1.9394,5.94,5.94,0,0,0-2.93,5.1724,6.25,6.25,0,0,0,1.98,4.8491,7.9293,7.9293,0,0,0,5.5547,1.8106,10.7991,10.7991,0,0,0,8.7129-4.2456,13.2688,13.2688,0,0,0,.1133,1.7456q.1113.8621.3847,2.0473h2.8281a14.37,14.37,0,0,1-.4863-2.3061,18.9634,18.9634,0,0,1-.1474-2.3921V17.91a6.7676,6.7676,0,0,0-2.4659-5.625,10.17,10.17,0,0,0-6.5175-1.961,10.5773,10.5773,0,0,0-6.71,2.0581,5.4963,5.4963,0,0,0-2.501,4.6661l.0459.1293,2.4893.0215a4.0015,4.0015,0,0,1,1.8672-3.3193,7.7741,7.7741,0,0,1,4.6953-1.3574,7.1074,7.1074,0,0,1,4.7187,1.4223,4.8593,4.8593,0,0,1,1.6856,3.9224v2.8882Zm5.4082,6.853a7.8836,7.8836,0,0,1-3.2364,3.33,10.07,10.07,0,0,1-5.16,1.3037,5.5677,5.5677,0,0,1-3.79-1.1963,3.9139,3.9139,0,0,1-1.3682-3.0923,4.3939,4.3939,0,0,1,2.2051-3.7177,10.3368,10.3368,0,0,1,5.8955-1.4981h5.4541Z"
                    fill="#fff"/>
                <path
                    d="M229.3721,29.7417a8.5436,8.5436,0,0,1-3.0557,1.918,12.3715,12.3715,0,0,1-4.3213.6684,7.1325,7.1325,0,0,1-5.7715-2.6455,9.96,9.96,0,0,1-2.1953-6.58V22.479h17.2891V20.28a10.076,10.076,0,0,0-2.58-7.2412,9.2982,9.2982,0,0,0-7.082-2.7154,9.5649,9.5649,0,0,0-7.32,3.2974,11.81,11.81,0,0,0-3.0215,8.2974V23.104a11.471,11.471,0,0,0,3.0429,8.19,10.0512,10.0512,0,0,0,7.6387,3.2329,13.9782,13.9782,0,0,0,5.0342-.8164,9.8722,9.8722,0,0,0,3.4971-2.2012ZM216.6084,14.7031a7.1424,7.1424,0,0,1,10.1387-.14,7.2105,7.2105,0,0,1,1.8779,5.0722V20.28H214.2783l-.0459-.1074A9.15,9.15,0,0,1,216.6084,14.7031Z"
                    fill="#fff"/>
                <path
                    d="M244.9639,34.5264a9.7547,9.7547,0,0,0,4.5254-.9917,8.2444,8.2444,0,0,0,3.1455-2.8448l.2256,3.3838H255.26V.4526h-2.6924V14.2891a8.7753,8.7753,0,0,0-3.1231-2.9312,9.0587,9.0587,0,0,0-4.4355-1.0342,8.1105,8.1105,0,0,0-6.9141,3.3511q-2.4769,3.3516-2.4775,8.9331v.4526a13.0413,13.0413,0,0,0,2.4775,8.3516A8.29,8.29,0,0,0,244.9639,34.5264ZM238.333,22.6089a13.29,13.29,0,0,1,1.7988-7.2842,5.9667,5.9667,0,0,1,5.375-2.8027,7.2353,7.2353,0,0,1,4.4356,1.2666,8.3755,8.3755,0,0,1,2.625,3.3008v10.996a7.5589,7.5589,0,0,1-2.7276,3.1172,7.9567,7.9567,0,0,1-4.3789,1.1255,6.1209,6.1209,0,0,1-5.3515-2.5107,11.4828,11.4828,0,0,1-1.7764-6.7539Z"
                    fill="#fff"/>
                <path
                    d="M270.6475,34.5264a9.5212,9.5212,0,0,0,4.707-1.11,8.2356,8.2356,0,0,0,3.168-3.1577l.2265,3.8149h2.3985V10.7544h-2.7149V27.3828a6.85,6.85,0,0,1-2.5576,3.6279,8.2509,8.2509,0,0,1-4.9111,1.3174,5.2922,5.2922,0,0,1-4.333-1.8574q-1.5279-1.8567-1.5274-6.1328V10.7544h-2.7158V24.2676q0,5.2587,2.1953,7.7588A7.647,7.647,0,0,0,270.6475,34.5264Z"
                    fill="#fff"/>
                <polygon
                    points="307.194 33.966 296.378 21.371 306.176 10.862 306.131 10.754 302.962 10.754 293.866 20.496 291.015 20.496 291.015 0.453 288.299 0.453 288.299 34.074 291.015 34.074 291.015 22.694 294.138 22.694 304.026 34.074 307.126 34.074 307.194 33.966"
                    fill="#fff"/>
                <path
                    d="M320.6357,20.7549a14.1588,14.1588,0,0,0-7.9082,1.9394,5.9382,5.9382,0,0,0-2.9306,5.1724,6.249,6.249,0,0,0,1.98,4.8491,7.9333,7.9333,0,0,0,5.5556,1.8106,10.7952,10.7952,0,0,0,8.7119-4.2456,13.4428,13.4428,0,0,0,.1133,1.7456q.1143.8621.3848,2.0473h2.8291a14.4575,14.4575,0,0,1-.4863-2.3061,18.9445,18.9445,0,0,1-.1475-2.3921V17.91a6.7657,6.7657,0,0,0-2.4668-5.625,10.1684,10.1684,0,0,0-6.5176-1.961,10.5786,10.5786,0,0,0-6.709,2.0581,5.4928,5.4928,0,0,0-2.5,4.6661l.045.1293,2.4892.0215a4.0031,4.0031,0,0,1,1.8662-3.3193,7.7816,7.7816,0,0,1,4.6963-1.3574,7.1012,7.1012,0,0,1,4.7178,1.4223,4.8565,4.8565,0,0,1,1.6855,3.9224v2.8882Zm5.4082,6.853a7.8861,7.8861,0,0,1-3.2353,3.33,10.07,10.07,0,0,1-5.1592,1.3037,5.5655,5.5655,0,0,1-3.791-1.1963,3.9178,3.9178,0,0,1-1.3691-3.0923,4.396,4.396,0,0,1,2.207-3.7177,10.3314,10.3314,0,0,1,5.8945-1.4981h5.4531Z"
                    fill="#fff"/>
                <path
                    d="M345.03,34.5264a10.43,10.43,0,0,0,6.541-2.1768,6.2889,6.2889,0,0,0,2.7148-5.41l-.0449-.1294h-2.3984a4.6969,4.6969,0,0,1-2.0606,4.0577,8.01,8.01,0,0,1-4.7519,1.46q-3.9815,0-5.86-2.6694a11.5448,11.5448,0,0,1-1.8779-6.8032v-.9038a11.4323,11.4323,0,0,1,1.8887-6.75,6.646,6.646,0,0,1,5.8281-2.68,7.2747,7.2747,0,0,1,4.8535,1.67,5.5532,5.5532,0,0,1,1.9805,4.4722h2.4209l.0449-.1294a7.16,7.16,0,0,0-2.5908-5.9483,10.043,10.043,0,0,0-6.709-2.2627A9.4757,9.4757,0,0,0,337.3135,13.6a12.5979,12.5979,0,0,0-2.7383,8.3618v.9053a12.6569,12.6569,0,0,0,2.7275,8.3945A9.5056,9.5056,0,0,0,345.03,34.5264Z"
                    fill="#fff"/>
                <path
                    d="M375.3086,10.7544l-7.3994,19.7847h-.1358l-.86-2.78-6.7207-17.0044h-3.01l9.459,23.0176-1.2891,3.1465A10.333,10.333,0,0,1,363.542,39.99a3.6014,3.6014,0,0,1-2.8291,1.1958c-.1807,0-.4717-.018-.8711-.0537s-.69-.0683-.8721-.0971l-.3164,2.1552a6.0262,6.0262,0,0,0,.9961.2051,8.3874,8.3874,0,0,0,1.1543.0967,5.7109,5.7109,0,0,0,4.4356-1.6592,13.2237,13.2237,0,0,0,2.4668-4.03l10.6347-27.0478Z"
                    fill="#fff"/>
                <path
                    d="M384.0205,41.5415a7.86,7.86,0,0,0,1.8106-5.55V10.7544h-2.6934V35.9917a5.884,5.884,0,0,1-1.1309,3.9116,3.9124,3.9124,0,0,1-3.1455,1.3257,6.7137,6.7137,0,0,1-1.1084-.1079,9.0142,9.0142,0,0,1-.9961-.2153l-.2949,2.1767a12.17,12.17,0,0,0,1.2559.3018,7.2885,7.2885,0,0,0,1.2793.1074A6.5148,6.5148,0,0,0,384.0205,41.5415Z"
                    fill="#fff"/>
                <rect x="383.0029" y="0.4526" width="2.6924" height="3.5347" fill="#fff"/>
                <path
                    d="M396.3086,17.77a7.974,7.974,0,0,1,2.5908-3.8115,7.2257,7.2257,0,0,1,4.65-1.436,5.8538,5.8538,0,0,1,4.4688,1.6626q1.5732,1.6634,1.5732,5.68V34.0737h2.7158V19.8711q0-4.9138-2.082-7.23a7.7354,7.7354,0,0,0-6.0645-2.3169A9.2071,9.2071,0,0,0,399.42,11.498a8.464,8.464,0,0,0-3.1787,3.33l-.25-4.0737h-2.3984V34.0737h2.7158Z"
                    fill="#fff"/>
                <path
                    d="M428.9854,20.7549a14.159,14.159,0,0,0-7.9083,1.9394,5.9382,5.9382,0,0,0-2.9306,5.1724,6.249,6.249,0,0,0,1.9795,4.8491,7.9335,7.9335,0,0,0,5.5556,1.8106,10.7956,10.7956,0,0,0,8.712-4.2456,13.4428,13.4428,0,0,0,.1132,1.7456q.1143.8621.3848,2.0473h2.8291a14.4575,14.4575,0,0,1-.4863-2.3061,18.9445,18.9445,0,0,1-.1475-2.3921V17.91a6.7657,6.7657,0,0,0-2.4668-5.625,10.1684,10.1684,0,0,0-6.5176-1.961,10.5781,10.5781,0,0,0-6.7089,2.0581,5.4923,5.4923,0,0,0-2.5,4.6661l.0449.1293,2.4892.0215a4.0031,4.0031,0,0,1,1.8662-3.3193A7.7816,7.7816,0,0,1,427.99,12.522a7.1012,7.1012,0,0,1,4.7178,1.4223,4.8566,4.8566,0,0,1,1.6856,3.9224v2.8882Zm5.4082,6.853a7.8869,7.8869,0,0,1-3.2354,3.33,10.07,10.07,0,0,1-5.1592,1.3037,5.5655,5.5655,0,0,1-3.791-1.1963,3.9178,3.9178,0,0,1-1.3691-3.0923,4.396,4.396,0,0,1,2.207-3.7177,10.3314,10.3314,0,0,1,5.8945-1.4981h5.4532Z"
                    fill="#fff"/>
                <path d="M103.1318,89.1123a8.8921,8.8921,0,1,0,8.8916,8.8921A8.8926,8.8926,0,0,0,103.1318,89.1123Z"
                      fill="#fff"/>
                <circle cx="336.0908" cy="98.0044" r="8.8918" fill="#fff"/>
                <path
                    d="M359.5361,50.3013a6.5092,6.5092,0,0,0-6.5215,6.5141l.001.0161h-.001v58.6456q0,10.464-4.5859,15.374a14.66,14.66,0,0,1-5.8642,3.8208v10.4922a28.5793,28.5793,0,0,0,15.1337-7.273q8.3643-7.8135,8.3643-22.414V56.8315h-.001l.001-.0161A6.5133,6.5133,0,0,0,359.5361,50.3013Z"
                    fill="#fff"/>
                <path
                    d="M282.7988,50.3013a6.5254,6.5254,0,0,0-6.5215,6.53h-.0009v63.1031l-.3877.1289L240.2334,50.3013H227.12V137.708a6.5569,6.5569,0,1,0,13.1133,0V74.5244l.3867-.1294,35.6563,69.9561h13.0478v-87.52h-.001A6.5284,6.5284,0,0,0,282.7988,50.3013Z"
                    fill="#fff"/>
                <path
                    d="M102.8408,48.9448q-14.2764,0-22.77,9.4307-8.4946,9.4314-8.4942,26.0962v25.8374q0,16.7314,8.462,26.0644,8.4609,9.3348,22.8017,9.334,14.5342,0,23.1895-9.334,8.6543-9.3324,8.6552-26.0644V84.4717q0-16.6656-8.6875-26.0962Q117.3087,48.9449,102.8408,48.9448Zm18.7969,61.3643q0,12.4688-4.877,18.83-4.8779,6.3639-13.92,6.3633-8.7846,0-13.5322-6.3956-4.7475-6.3939-4.7481-18.7973V84.2778q0-12.2717,4.7159-18.6357,4.7138-6.361,13.5644-6.3618,8.9766,0,13.8877,6.3618,4.9088,6.364,4.9092,18.6357Z"
                    fill="#fff"/>
                <path
                    d="M432.0137,102.6362q-6.7192-6.9192-19.5723-10.93-9.8188-3.3575-14.081-7.4605a13.473,13.473,0,0,1-4.2637-10.1094,15.4945,15.4945,0,0,1,3.9726-10.9482q3.9728-4.2949,11.2715-4.2959,7.686,0,11.918,5.1538,4.2289,5.1519,4.2305,13.38v.0044a6.3589,6.3589,0,1,0,12.7177,0l.0078-.3872A26.617,26.617,0,0,0,430.4961,57.18q-7.9789-8.2354-21.0254-8.2354-12.5333,0-20.5088,7.1294A23.3464,23.3464,0,0,0,380.9844,74.3q0,10.7739,7.17,17.3223T408.76,102.2358q9.3647,3.2307,13.1123,7.4932a15.9323,15.9323,0,0,1,3.747,10.916q0,6.9134-4.167,11.0132-4.1659,4.1031-11.53,4.1016-8.2032,0-13.2735-4.6348t-5.07-14.2866v-.0049a6.3682,6.3682,0,0,0-12.7363.0054c0,.0708.0078.1391.0107.2094-.163,8.9449,2.8594,16.2637,9.0752,21.1988a34.3362,34.3362,0,0,0,21.9942,7.46q12.92,0,20.8642-6.79t7.9453-18.3672Q438.7314,109.5559,432.0137,102.6362Z"
                    fill="#fff"/>
                <path
                    d="M319.1025,115.4771V56.8315h-.0009l.0009-.0161a6.4917,6.4917,0,1,0-12.9834,0l.001.0161h-.001v58.6456q0,14.5986,8.2041,22.414a27.93,27.93,0,0,0,15.294,7.3447v-10.51a14.4731,14.4731,0,0,1-5.9932-4.0679Q319.1021,125.5543,319.1025,115.4771Z"
                    fill="#fff"/>
                <path
                    d="M155.8076,57.9238q-8.5913,8.9788-8.5908,25.32V93.4165h13.0478v-10.39q0-11.7759,4.9747-17.9546,4.9731-6.18,13.5644-6.18,8.5269,0,12.8223,4.62t4.6181,13.4673h.0147a6.2595,6.2595,0,0,0,12.5166-.22c0-.0489-.0059-.0967-.0068-.1455l.0068-.0225c-.0039-.1177-.02-.2266-.0254-.3437-.0049-.0586-.0068-.1182-.0137-.1768q-.6489-12.7274-8.1-19.8589-7.623-7.2671-22.0263-7.2671Q164.3984,48.9448,155.8076,57.9238Z"
                    fill="#fff"/>
                <path
                    d="M179.9668,106.24h16.084v21.3814a14.6493,14.6493,0,0,1-5.5235,5.8462q-3.7147,2.2932-10.4316,2.2925-9.1728,0-14.5019-6.3077t-5.3292-18.3437v-4.7451H147.2168V111.15q0,16.6011,9.0439,25.5786,9.0426,8.9794,23.835,8.979,11.3028,0,18.5713-4.5215,7.2656-4.52,10.4961-10.206V96.7446H179.9668Z"
                    fill="#fff"/>
                <path
                    d="M54.248,109.2661a6.3044,6.3044,0,0,0-6.3115,6.3491c0,.0625.0078.1236.0088.1856h-.01q-.13,10.4642-3.9082,15.2114T32.11,135.76a14.7262,14.7262,0,0,1-12.8223-6.5352q-4.5542-6.5339-4.5547-18.8276v-4.0337H1.751V110.44q0,16.6647,8.2676,25.9663T32.11,145.7075q13.5644,0,20.9931-7.7192,7.4268-7.7175,7.4922-21.8l-.0029-.3872h-.0069c.002-.062.01-.1231.01-.1856A6.335,6.335,0,0,0,54.248,109.2661Z"
                    fill="#fff"/>
                <path
                    d="M31.7217,48.9448q-13.629,0-21.8,9.3662Q1.7494,67.6773,1.751,84.3428v9.0737H14.7334V84.062q0-12.1,4.458-18.6343a14.3064,14.3064,0,0,1,12.53-6.5356q8.3334,0,12.209,4.876T48,78.978v.0034a6.2984,6.2984,0,1,0,12.5957,0v-.3876Q60.4024,64.06,52.9736,56.5024T31.7217,48.9448Z"
                    fill="#fff"/>
            </g>
        </symbol>
        <symbol id="waves" viewBox="0 0 1920 118.8856" preserveAspectRatio="none">
            <g>
                <path
                    d="M0,55.35c46.8335-14.082,58.0851-39.1286,150.4512-34.4624S311.1919-4.134,382.7431.491,596.02,72.4941,659.1151,70.4824s184.4431-30.584,280.2061-25.0153,164.7123,35.0738,292.2035,37.0855,105.0533-9.272,163.1817-31.9735,135.2818-28.35,212.0367-12.9273S1897.2337,102.284,1920,102.9545v15.9311H0Z"
                    fill="#ffffff" fill-opacity="0.5"></path>
                <path
                    d="M1920,41.3413c-93.7982,3.6583-172.933,17.886-245.5936,21.512s-227.89,19.3977-291.9633,17.8205S1195.14,56.6962,1097.8927,61.062,930.6266,88.56,801.1587,90.1369,633.0653,63.6347,537.1834,60.0978c-81.1193-2.9922-144.2449,10.0615-222.19,22.1532S23.1193,45.9918,0,46.5175v72.3681H1920Z"
                    fill="#ffffff" fill-opacity="0.8"></path>
                <path
                    d="M1920,41.3413C1903.936,24.994,1867.18,10.85,1837.013,14.35c-54.0387,6.27-56.407,14.93-98.3935,19.5553S1606.9348,58.6477,1569.91,56.636s-108.2317-30.584-164.4256-25.0153-96.6536,35.0738-171.4658,37.0855-61.6455-9.272-95.7555-31.9735S1058.88,8.3823,1013.84,23.8054,824.6519,93.5174,811.2926,94.188s5.7255,21.4582,5.7255,21.4582L1920,118.8856Z"
                    fill="#ffffff" fill-opacity="0.5"></path>
                <path
                    d="M924.513,118.8856c-30.1332-16.2466-59.0579-55.5532-119.1-55.1779S707.8851,78.9868,661.3548,80.2872s-146.1295,14.308-187.0459,9.3687S355.1666,50.5305,292.9028,51.65s-107.5751,27.404-190.3661,23.496C21.37,71.3146,34.6222,61.8554,0,37.5488v81.3368Z"
                    fill="#ffffff" fill-opacity="0.5"></path>
            </g>
        </symbol>
        <symbol id="backlight" viewBox="0 0 978.9558 1080.7314" preserveAspectRatio="none">
            <polygon points="978.956 1080.731 0 1080.731 100.01 0 878.946 0 978.956 1080.731" fill="#098cc6"
                     opacity="0.4"/>
        </symbol>
    </defs>
</svg>

<div class="fixed-full-screen">
    <ip-animated-circles></ip-animated-circles>
</div>

<div class="container-fluid fixed-full-screen d-none d-xs-block">
    <div class="row backlight h-100 ms-n9 ms-sm-0">
        <div class="col-12 col-md-10 offset-md-1 col-xl-6 offset-xl-3">
            <svg class="vh-100 w-100">
                <use xlink:href="#backlight"></use>
            </svg>
        </div>
    </div>
</div>
<div class="container-fluid fixed-full-screen text-light text-start text-sm-center position-relative overflow-hidden"
     [class.subscribed]="isSubscribed">
    <div class="main-row row vh-100">
        <div class="col-12 col-xs-8 col-sm-6 offset-sm-3 col-xl-4 offset-xl-4 position-relative">
            <div class="circle-logo">
                <svg>
                    <use xlink:href="#circle-logo"></use>
                </svg>
            </div>
            <div class="text-logo mt-3">
                <svg>
                    <use xlink:href="#text-logo"></use>
                </svg>
            </div>
            <div class="happy-girl d-sm-none d-xl-block">
                <img [src]="girlImage" class="w-100">
            </div>
            <div class="promotion-text-box mt-vh" *ngIf="!isSubscribed">
                Skorzystaj już dziś z <span class="intensive"><span class="no-wrap">6 miesięcy</span> za DARMO!</span>
            </div>
            <div class="promotion-text-box mt-vh" *ngIf="isSubscribed">
                Cieszymy się, że skorzystałeś/-łaś z naszej oferty<br><span
                class="intensive">6 miesięcy za DARMO!</span>
            </div>
            <div class="email-box mt-vh" [class.error]="validationError" *ngIf="!isSubscribed">
                <input [formControl]="emailControl" autocomplete="off" class="w-100" id="email" type="text"
                       placeholder="Twój adres email">
                <div *ngIf="validationError"
                     class="error-msg small">{{validationError}}
                </div>
            </div>
            <div class="button-box mt-vh-small" [class.error]="globalError" *ngIf="!isSubscribed">
                <a (click)="send()" class="btn btn-action w-100" [class.in-progress]="sendingInProgress">
                    Zapisz się
                    <span class="spinner-border spinner-border-sm progress-icon" role="status"
                          aria-hidden="true"></span>
                </a>
                <div *ngIf="globalError" class="error-msg small">{{globalError}}</div>
            </div>
            <div class="summary-box mt-vh" *ngIf="!isSubscribed">
                Zapisz się do naszego newslettera, a powiadomimy Cię o uruchomieniu platformy COGNUS.
                <strong>Każda</strong> zapisana osoba otrzyma pełny dostęp na okres aż <strong><span class="no-wrap">6 miesięcy</span>
                za DARMO!</strong>
            </div>
            <div class="summary-box mt-vh-small" *ngIf="isSubscribed">
                Powiadomimy Cię mailowo o uruchomieniu platformy COGNUS.
            </div>
        </div>
        <div class="d-none d-sm-flex col-sm-3  d-xl-none">
            <div class="happy-girl">
                <img [src]="girlImage" class="w-100">
            </div>
        </div>
    </div>
    <div class="waves-box">
        <svg class="h-100 w-100">
            <use xlink:href="#waves"></use>
        </svg>
    </div>
</div>


<ip-dev-tools></ip-dev-tools>
