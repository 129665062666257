import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralResponse} from '../models';
import {FileResponse} from '../models/api/media/file.response';
import {FilesResponse} from '../models/api/media/files.response';
import {FileModel} from '../models/media/file.model';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class ApiMediaImagesService {

    constructor(private readonly http: HttpClient, private readonly config: ConfigService) {
    }

    /**
     * Get a full image record, with all content.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param id Id of the image file.
     */
    public getImageById(id: string): Observable<FileResponse> {
        return this.http.get<FileResponse>(`${this.config.apiUrl}/media/images/${id}`);
    }

    /**
     * Returns a set of image files sorted by date of creation. Each record contains only general data, without details like content.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param count Number of records that are returned in each page / batch.
     * @param pageIndex Current page index to fetch.
     * @param sorting A sorting order of image files by date of creation.
     */
    public getImages(
        count: number = 30,
        pageIndex: number = 0,
        sorting: 'asc' | 'desc' = 'desc'
    ): Observable<FilesResponse> {
        return this.http.get<FilesResponse>(
            `${this.config.apiUrl}/media/images?pageSize=${count}&pageIndex=${pageIndex}&sort=${sorting}`
        );
    }

    /**
     * Creates a new file (uploads new file). If request contains id or createdDate they will be ignored. New record id is returned in
     * response.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param data A new image file record to upload.
     */
    public createImage(data: FileModel): Observable<GeneralResponse> {
        return this.http.post<GeneralResponse>(`${this.config.apiUrl}/media/images`, data);
    }

    /**
     * Permanently removes an image file record, operation cannot be undone.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param fileId Id of the record to remove.
     */
    public deleteImage(fileId: string): Observable<GeneralResponse> {
        return this.http.delete<GeneralResponse>(`${this.config.apiUrl}/media/images/${fileId}`);
    }

    /**
     * Get a absolute file URL by its ID.
     * @param fileId Id of the file.
     */
    public getFileUrl(fileId: string): string {
        return `${this.config.filesUrl}/${fileId}`;
    }
}
