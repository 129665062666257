import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[ipDragAndDrop]'
})
export class DragAndDropDirective {

    private isDraggingOver: boolean = false;

    @Output() public onFileDrop = new EventEmitter<FileList>();
    @Output() public onDragOver = new EventEmitter<void>();
    @Output() public onDragLeave = new EventEmitter<void>();

    @HostListener('dragover', ['$event'])
    public onDragOverListener(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.isDraggingOver) {
            this.isDraggingOver = true;
            console.log('ON DRAG OVER');
            this.onDragOver.emit();
        }
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeaveListener(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isDraggingOver) {
            this.isDraggingOver = false;
            console.log('ON DRAG LEAVE');
            this.onDragLeave.emit();
        }
    }

    @HostListener('drop', ['$event'])
    public onDropListener(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        console.log('ON DRAG DROP');
        let files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            console.log(files);
            this.onFileDrop.emit(files);
        }
    }
}
