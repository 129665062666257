<div class="file-uploader-container"
     [class.theme-blue]="style === 'Blue'"
     [class.theme-light]="style === 'Light'"
     [class.dragging-file-over]="isDraggingFileOver"
     [class.uploading-file-progress]="isUploadInProgress"
     [class.image-preview-mode]="isImagePreview"
     [class.error-mode]="isError">
    <div class="inner-container row align-items-center justify-content-center"
         ipDragAndDrop
         (onDragOver)="onDrag(true)"
         (onDragLeave)="onDrag(false)"
         (onFileDrop)="onFileDragDrop($event)">
        <div class="content col-12 text-center">
            <div class="image-preview-box text-center">
                <img class="image" [src]="filePreview">
                <div class="image-preview-small-text"><small>Przeciągnij tutaj inny obrazek, lub...</small></div>
                <div>
                    <a (click)="onUploadImageManually()" class="ip-btn-light ip-btn-small ip-btn-no-upper-case">
                        Wybierz obrazek z komputera...
                    </a>
                </div>
            </div>
            <div class="default-box">
                <div class="title">Przeciągnij i upuść plik tutaj</div>
                <div class="icon"><i class="bi bi-cloud-upload"></i></div>
                <div class="small-text"><small>Lub, jeśli wolisz...</small></div>
                <div class="upload-btn">
                    <a (click)="onUploadImageManually()" class="ip-btn-light ip-btn-small ip-btn-no-upper-case">
                        Wybierz obrazek z komputera...
                    </a>
                </div>
            </div>
            <div class="image-dragging-box">
                <div class="icon"><i class="bi bi-cloud-upload"></i></div>
            </div>
            <div class="file-upload-box text-center">
                <span class="spinner-border spinner-5" role="status" aria-hidden="true"></span>
                <br>
                Przesyłanie pliku...
            </div>
            <div class="error-box text-center">
                <div class="title">Niepowodzenie</div>
                <div class="message">{{errorMessage}}</div>
                <div class="dismiss-btn">
                    <a (click)="onDismissError()" class="ip-btn-light ip-btn-small ip-btn-no-upper-case">
                        Rozumiem
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="action-buttons-box text-center">
        <a (click)="onCancelButton()" class="ip-btn-light" [attr.disabled]="isUploadInProgress">
            Anuluj
        </a>
        <a (click)="onOkButton()" class="ip-btn-light" [attr.disabled]="isUploadInProgress || !filePreview">
            OK
        </a>
    </div>
</div>
