/**
 * Defines anf object color theme when object is active / selected.
 */
export enum ObjectColor {
    Green = 'GREEN',
    Red = 'RED',
    Yellow = 'YELLOW',
    Black = 'BLACK',
    Blue = 'BLUE'
}
