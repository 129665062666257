import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {AppEnvironment, CommonLibConfig, CommonLibModule} from 'common-lib';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {ComingSoonComponent} from './coming-soon/coming-soon.component';

@NgModule({
    declarations: [
        AppComponent,
        ComingSoonComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        CommonLibModule.forRoot(new CommonLibConfig({
            apiUrl: environment.apiUrl,
            filesUrl: environment.filesUrl,
            environment: environment.production ? AppEnvironment.Production : AppEnvironment.Test
        }))
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
