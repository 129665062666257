import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FileModel} from '../../models/media/file.model';
import {FileUploaderService, UploadInfo, UploadStatus} from './file-uploader.service';

@Component({
    selector: 'ip-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    providers: [FileUploaderService]
})
export class FileUploaderComponent implements OnInit {
    private file: FileModel | undefined;

    /**
     * Defines a name of the general control styling.
     */
    @Input() public style: 'Blue' | 'Light' = 'Blue';
    /**
     * Event fired when user clicks OK button with already selected image.
     * If no image is selected even won't be fired.
     */
    @Output() public onSave: EventEmitter<FileModel> = new EventEmitter<FileModel>();
    /**
     * Event fired when user clicks CANCEL button.
     */
    @Output() public onCancel: EventEmitter<void> = new EventEmitter<void>();

    public get filePreview(): SafeUrl | undefined {
        if (this.file?.content) {
            return this.sanitizer.bypassSecurityTrustUrl(this.uploader.getFileUrl(this.file));
        }
        return undefined;
    }

    public isDraggingFileOver: boolean = false;
    public isUploadInProgress: boolean = false;
    public isImagePreview: boolean = false;
    public isError: boolean = false;
    public errorMessage: string | undefined;

    constructor(private element: ElementRef, private uploader: FileUploaderService, private sanitizer: DomSanitizer) {
    }

    public ngOnInit(): void {
    }

    public onDismissError(): void {
        this.isError = false;
    }

    public onUploadImageManually(): void {

        this.uploader
            .openFileSelectionWindow(this.element.nativeElement as HTMLElement)
            .subscribe((info: UploadInfo) => this.handleUploadStatusUpdates(info));
    }

    public onDrag(dragOver: boolean): void {
        this.setStatus(dragOver);
    }

    public onFileDragDrop(files: FileList): void {
        this.setStatus(false);
        if (files.length === 1) {
            this.uploader
                .uploadFile(files[0])
                .subscribe((info: UploadInfo) => this.handleUploadStatusUpdates(info));
        }
    }

    public onCancelButton(): void {
        this.onCancel.emit();
        this.file = undefined;
    }

    public onOkButton(): void {
        if (this.file) {
            this.uploader.sendToApi(this.file)
                .subscribe((uploadedFile) => {
                    if (uploadedFile) {
                        this.onSave.emit(this.file);
                        this.file = undefined;
                    }
                    else {
                        alert('Nie udało się zapisać pliku. Prosimy spróbować ponownie nacisnąć przycisk "OK".');
                    }
                });
        }
    }

    private setStatus(dragging: boolean = false, uploading: boolean = false, preview: boolean = false, error: boolean = false): void {
        this.isDraggingFileOver = dragging;
        this.isUploadInProgress = uploading;
        this.isImagePreview = preview;
        this.isError = error;
    }

    private handleUploadStatusUpdates(info: UploadInfo): void {
        this.errorMessage = undefined;
        switch (info.status) {
            case UploadStatus.Loading:
                this.setStatus(false, true, false, false);
                break;

            case UploadStatus.Aborted:
                if (this.file) {
                    // go back to preview of currently selected image
                    this.setStatus(false, false, true, false);
                }
                else {
                    // go back to default state if no images in preview available
                    this.setStatus(false, false, false, false);
                }
                break;

            case UploadStatus.Error:
                this.errorMessage = info.errorMessage || 'Przesyłanie pliku nie powiodło się.<br>Wybierz inny plik lub spróbuj ponownie.';
                this.setStatus(false, false, false, true);
                break;

            case UploadStatus.Success:
                this.file = info.file as FileModel;
                this.setStatus(false, false, true, false);
                break;

        }
    }
}
