import {Injectable} from '@angular/core';
import {ApiSubscriptionsService, ErrorResponse, GeneralResponse, WindowService} from 'common-lib';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {
    EMAIL_PATTERN,
    INVALID_EMAIL_FORMAT_ERR,
    MISSING_EMAIL_ERR,
    NO_SERVER_CONNECTION_ERR,
    SENDING_ERR,
    SUBSCRIPTION_DONE_KEY,
    SUBSCRIPTION_ERR
} from './coming-soon.consts';

@Injectable({providedIn: 'root'})
export class ComingSoonService {
    /**
     * Gets information if the user has already been subscribed from the current browser.
     */
    public get isSubscribed(): boolean {
        return !!this.window.localStorage.getItem(SUBSCRIPTION_DONE_KEY);
    }

    constructor(private subscriptions: ApiSubscriptionsService, private window: WindowService) {

    }

    public subscribeEmail(email: string): Observable<EmailSubscriptionResult> {
        const validationError = this.validateEmail(email);

        if (!validationError) {
            // send email to subscription endpoint
            return this.subscriptions.createSubscription(email)

                // Errors handling pipe
                       .pipe(
                           mergeMap((result: GeneralResponse & ErrorResponse) => {
                               if (!result.error) {
                                   return this.ofResult(validationError);
                               }
                               return this.ofResult(validationError, `${SUBSCRIPTION_ERR}: ${result.error}`);
                           }),
                           catchError((error) => {
                               if (error.status === 0) {
                                   return this.ofResult(validationError, NO_SERVER_CONNECTION_ERR);
                               }
                               return this.ofResult(validationError, SENDING_ERR);
                           })
                       )

                // action handling pipe
                       .pipe(
                           tap((result: EmailSubscriptionResult) => {
                               if (!result.globalError) {
                                   this.window.localStorage.setItem(SUBSCRIPTION_DONE_KEY, 'DONE');
                               }
                           })
                       );
        }

        return of(<EmailSubscriptionResult> {
            emailValidationError: validationError
        });
    }

    private validateEmail(email: string): string | undefined {
        if (!email || email.trim() === '') {
            return MISSING_EMAIL_ERR;
        }
        else if (!EMAIL_PATTERN.test(email)) {
            return INVALID_EMAIL_FORMAT_ERR;
        }
        return undefined;
    }

    private ofResult(validationError?: string, globalError?: string): Observable<EmailSubscriptionResult> {
        return of(<EmailSubscriptionResult> {
            emailValidationError: validationError,
            globalError
        });
    }
}

export class EmailSubscriptionResult {
    public emailValidationError?: string;
    public globalError?: string;
}
