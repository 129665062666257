import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DomObserverService {
    private observer!: MutationObserver;
    private $events: ReplaySubject<MutationRecord[]> = new ReplaySubject<MutationRecord[]>(1);

    public observeAttributes(htmlElement: HTMLElement): Observable<MutationRecord[]> {
        return this.initObserver(htmlElement, {
            attributes: true,
            childList: false,
            subtree: false
        });
    }

    public observeChildren(htmlElement: HTMLElement): Observable<MutationRecord[]> {
        return this.initObserver(htmlElement, {
            attributes: false,
            childList: true,
            subtree: false
        });
    }

    public observeSubtree(htmlElement: HTMLElement): Observable<MutationRecord[]> {
        return this.initObserver(htmlElement, {
            attributes: false,
            childList: false,
            subtree: true
        });
    }

    public observeAll(htmlElement: HTMLElement): Observable<MutationRecord[]> {
        return this.initObserver(htmlElement, {
            attributes: true,
            childList: true,
            subtree: true
        });
    }

    public destroy(): void {
        this.observer.disconnect();
        this.$events.complete();
    }

    private initObserver(htmlElement: HTMLElement, options: MutationObserverInit): Observable<MutationRecord[]> {
        if (this.observer) {
            this.destroy();
        }
        this.observer = new MutationObserver((changed) => {
            this.$events.next(changed);
        });
        this.observer.observe(htmlElement, options);
        return this.$events.asObservable();
    }
}
