<!-- grid-overlay -->
<div *ngIf="showGrid" class="grid container-fluid">
    <div class="row">
        <div class="col-1">
            <div class="column-fill"><span>1</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>2</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>3</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>4</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>5</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>6</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>7</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>8</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>9</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>10</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>11</span></div>
        </div>
        <div class="col-1">
            <div class="column-fill"><span>12</span></div>
        </div>
    </div>

    <div class="sizes">
        <div class="d-none d-xxxl-block">XXXL 2300+</div>
        <div class="d-none d-xxl-block d-xxxl-none">XXL 1920..2299</div>
        <div class="d-none d-xl-block d-xxl-none">XL 1600..1919</div>
        <div class="d-none d-lg-block d-xl-none">LG 1024..1599</div>
        <div class="d-none d-md-block d-lg-none">MD 768..1023</div>
        <div class="d-none d-sm-block d-md-none">>SM 480..767</div>
        <div class="d-sm-none">>XS 0...479</div>
    </div>
</div>

<a (click)="toggleGrid()" class="grid-toggler">
    <span class="icon"></span>
</a>
