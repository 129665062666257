import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ip-dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.scss']
})
export class DevToolsComponent implements OnInit {

    @Input() public showGrid: boolean;

    constructor() {
        this.showGrid = false;
    }

    public ngOnInit(): void {
    }

    public toggleGrid(): void {
        this.showGrid = !this.showGrid;
    }

}
