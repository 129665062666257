import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralResponse, UserModel} from '../models';
import {UserResponse} from '../models/api/users/user.response';
import {UsersResponse} from '../models/api/users/users.response';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class ApiUsersService {

    constructor(private readonly http: HttpClient, private readonly config: ConfigService) {
    }

    public getUserMe(): Observable<UserResponse> {
        return this.http.get<UserResponse>(`${this.config.apiUrl}/users/me`);
    }

    /**
     * Get a full user record, with all user trainings.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param id Id of the training to fetch.
     */
    public getUserById(id: string): Observable<UserResponse> {
        return this.http.get<UserResponse>(`${this.config.apiUrl}/users/${id}`);
    }

    /**
     * Returns a set of users sorted by name. Each record contains only general data, without details like trainings.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param count Number of records that are returned in each page / batch.
     * @param pageIndex Current page index to fetch.
     * @param sorting A sorting order of users by name. By default - ascending order.
     */
    public getUsers(
        count: number = 30,
        pageIndex: number = 0,
        sorting: 'asc' | 'desc' = 'desc'
    ): Observable<UsersResponse> {
        return this.http.get<UsersResponse>(
            `${this.config.apiUrl}/users?pageSize=${count}&pageIndex=${pageIndex}&sort=${sorting}`
        );
    }

    /**
     * Creates a new user. If request contains id it will be ignored. New record id is returned in
     * response.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param data A new user to create.
     */
    public createUser(data: UserModel): Observable<GeneralResponse> {
        return this.http.post<GeneralResponse>(`${this.config.apiUrl}/users`, data);
    }

    /**
     * Permanently removes a user record, operation cannot be undone.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record to remove.
     */
    public deleteUser(userId: string): Observable<GeneralResponse> {
        return this.http.delete<GeneralResponse>(`${this.config.apiUrl}/users/${userId}`);
    }

    /**
     * Updates existing user with given data.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record to update.
     * @param data User data to be updated.
     */
    public updateUser(userId: string, data: Partial<UserModel>): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/users/${userId}`, data);
    }

    /**
     * Set the given user as blocked - not able to login. User will get information during login
     * that his account has been blocked.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record.
     */
    public blockUserAccount(userId: string): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/users/${userId}/block`, {});
    }

    /**
     * Set the given user as unblocked - if the account was blocked.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record.
     */
    public unblockUserAccount(userId: string): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/users/${userId}/unblock`, {});
    }

    /**
     * Set the given user as active - account normally is activated after verification.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record.
     */
    public activateUserAccount(userId: string): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/users/${userId}/activate`, {});
    }

    /**
     * Set the given user as inactive - account normally is inactive just after registration.
     * There may be a situation that we need to deactivate account, so we added it here also.
     * NOTE: Some of manipulation methods may be restricted to specific user roles only.
     * @param userId Id of the record.
     */
    public deactivateUserAccount(userId: string): Observable<GeneralResponse> {
        return this.http.put<GeneralResponse>(`${this.config.apiUrl}/users/${userId}/deactivate`, {});
    }
}
