import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {PopupComponent} from './popup.component';

@Injectable({
    providedIn: 'root'
})
export class PopupService {

    private popups: { [id: string]: PopupComponent } = {};
    private current: string | undefined;
    private unlistenWindowResize: (() => void) | undefined;
    private renderer: Renderer2;

    constructor(private rendererFactory2: RendererFactory2) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
    }

    public show(id: string): void {
        if (this.popups[id]) {
            this.popups[id].visible = true;
            if (this.current) {
                this.hide(this.current);
                this.current = undefined;
            }
            this.current = id;
            this.startResizeListening();
        }
    }

    public hide(id: string): void {
        if (this.popups[id]) {
            this.popups[id].visible = false;
            this.current = undefined;
            this.stopResizeListening();
        }
    }

    public register(popup: PopupComponent): void {
        this.popups[popup.id] = popup;
    }

    public unregister(popup: PopupComponent): void {
        delete this.popups[popup.id];
    }

    public isVisible(id: string): boolean {
        return this.current === id;
    }

    private startResizeListening(): void {
        if (!this.unlistenWindowResize) {
            this.unlistenWindowResize = this.renderer.listen('window', 'resize', (event: Event) => {
                if (this.current) {
                    this.hide(this.current);
                }
            });
        }
    }

    private stopResizeListening(): void {
        if (this.unlistenWindowResize) {
            this.unlistenWindowResize();
            this.unlistenWindowResize = undefined;
        }
    }
}
