import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ApiSubscriptionsService, WindowService} from 'common-lib';
import {of, Subject} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {ComingSoonService, EmailSubscriptionResult} from './coming-soon.service';


@Component({
    selector: 'ip-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
    providers: [
        ComingSoonService
    ]
})
export class ComingSoonComponent implements OnInit {

    private globalError$: Subject<string> = new Subject<string>();
    public emailControl: UntypedFormControl = new UntypedFormControl('', []);
    public sendingInProgress: boolean = false;
    public globalError?: string;
    public validationError?: string;

    public get isSubscribed(): boolean {
        return this.comingSoon.isSubscribed;
    }

    public get girlImage(): string {
        return this.isSubscribed ? '/assets/welcome-girl-ok.png' : '/assets/welcome-girl.png';
    }

    constructor(private subscriptions: ApiSubscriptionsService, private window: WindowService, private comingSoon: ComingSoonService) {

    }

    public ngOnInit(): void {
        if (!this.isSubscribed) {
            this.globalError$.pipe(
                switchMap((msg: string) => {
                    this.globalError = msg;
                    if (!this.globalError) {
                        return of(msg);
                    }
                    return of(msg).pipe(delay(5000));
                })
            ).subscribe((msg: string) => {
                this.globalError = undefined;
            });
        }
    }

    public send(): void {
        this.globalError$.next(undefined);
        this.sendingInProgress = true;

        // subscription is auto completed no need to unsubscribe
        this.comingSoon.subscribeEmail(this.emailControl.value)
            .subscribe((result: EmailSubscriptionResult) => {
                this.sendingInProgress = false;
                this.globalError$.next(result.globalError);
                this.validationError = result.emailValidationError;
            });
    }

}
