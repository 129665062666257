import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {of, timer} from 'rxjs';
import {delay} from 'rxjs/operators';
import {AuthService} from '../../services';
import {LoginActionEvent, LoginActionType} from './login-action-event.interface';

@Component({
    selector: 'ip-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @Input()
    public showProgressbar: boolean = true;

    @Input()
    public progressBarPercent: number = 50;

    @Output()
    public action = new EventEmitter<LoginActionEvent>();

    public form!: UntypedFormGroup;
    public loginControl!: UntypedFormControl;
    public passwordControl!: UntypedFormControl;
    public serverError: string | undefined;
    public loggingInProgress: boolean = true;
    public loginSucceed: boolean = false;

    constructor(private formBuilder: UntypedFormBuilder, private auth: AuthService) {
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.loginControl = this.form.get('login') as UntypedFormControl;
        this.passwordControl = this.form.get('password') as UntypedFormControl;

        // validate if the current session is valid
        this.auth.isCurrentSessionValid()
            .subscribe((isValid: boolean) => {
                this.loggingInProgress = false;
                if (isValid) {
                    this.proceedSuccessLogin();
                }
            });
    }


    public onLogin(): void {
        this.serverError = undefined;

        if (this.form.valid) {
            this.loggingInProgress = true;

            this.auth.login(this.loginControl.value, this.passwordControl.value)
                .subscribe(
                    (token: string) => {
                        this.loggingInProgress = false;
                        this.proceedSuccessLogin();
                    },
                    (error: any) => {
                        let errorMsg: string = '';
                        this.loggingInProgress = false;
                        this.loginSucceed = false;
                        if (error instanceof HttpErrorResponse) {
                            errorMsg = error.error?.details;
                            if (error.error?.details === 'LOGIN_INVALID') {
                                this.loginControl.setErrors({
                                    invalidLogin: 'INVALID_LOGIN'
                                });
                            }
                            else if (error.error?.details === 'PASSWORD_INVALID') {
                                this.passwordControl.setErrors({
                                    invalidPassword: 'INVALID_PASSWORD'
                                });
                            }
                            else if (error.error?.details === 'USER_ACCOUNT_NOT_ACTIVATED') {
                                this.serverError = `Konto jest nieaktywne`;
                            }
                            else if (error.error?.details === 'USER_ACCOUNT_BLOCKED') {
                                this.serverError = `Konto jest zablokowane`;
                            }
                            else {
                                switch (error.status) {
                                    case 404:
                                        this.serverError = '404 Nie znaleziono';
                                        errorMsg = 'NOT_FOUND_404';
                                        break;
                                    case 500:
                                        this.serverError = '500 Błąd serwera';
                                        errorMsg = 'SERVER_ERROR_500';
                                        break;
                                    default:
                                        this.serverError = `${error.status}`;
                                        errorMsg = `ERROR_${error.status}`;
                                }
                            }
                        }

                        this.action.emit(<LoginActionEvent> {
                            action: LoginActionType.LoginFailed,
                            error: errorMsg
                        });
                    }
                );
        }
    }

    private proceedSuccessLogin(): void {
        this.loginSucceed = true;

        // wait for all animations to finish (1.5s) and emit SUCCEED event

        of({}).pipe(delay(1500))
              .subscribe(() => {
                  this.action.emit(<LoginActionEvent> {
                      action: LoginActionType.LoginSuccess
                  });
              });
    }

}
